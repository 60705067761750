<template>
  <div class="listPage">
    <b-container class="">
      <b-row class="row">
        <b-col md="12" class="my-3">
          <h5 v-if="result === null">Preberi QR kodo dokumenta</h5>
          <h5 v-if="result !== null">Rezultat skeniranja QR kode</h5>
        </b-col>
      </b-row>
      <b-row class="row">
        <b-col md="12" class="overflow-hidden">
          <div class="fullWidthOnMobile">

            <div v-if="result === null">
              <b-row>
                <b-col md="12" class="text-center">
<!--                  <qrcode-stream @decode="onDecode" @init="onInit" :camera="camera" />-->
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12" class="text-center my-2">
                  ali
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12" class="text-center my-2 mb-5">
                  <h4>preberi kodo iz slikovne datoteke</h4>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12" class="text-center mb-5">
                  <qrcode-capture @decode="onDecode" @init="onInit" :capture="false" />
                </b-col>
              </b-row>
            </div>
            <div v-if="result !== null" class="p-2">
<!--              <pre>-->
<!--                {{result}}-->
<!--              </pre>-->
              <b-alert :show="true">
                <font-awesome-icon icon="exclamation-triangle" class="mr-4"></font-awesome-icon> TODO: get document with uuid: <br/><b>{{result}}</b><br/> via Archeía service
              </b-alert>

              <b-button variant="info" class="my-3" @click="onNewScan">Novo skeniranje</b-button>

              <iframe src="https://dev.svon.si/sample.pdf" style="min-height: 650px; width: 100%; border: none;" allowfullscreen></iframe>



            </div>

          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { QrcodeCapture } from 'vue-qrcode-reader';

export default {
  name: "QrCodeScanner",
  components: { QrcodeCapture },
  data() {
    return {
      error: "",
      camera: 'auto',
      options: [
        { text: "rear camera (default)", value: "environment" },
        { text: "front camera", value: "user" },
        { text: "force file dialog", value: false },
      ],
      result: null,
    };

  },
  mounted() {
    const encryptedText = this.CryptoJS.AES.encrypt("529e87ad-cbe5-432a-8c82-ee6f7590b877", "Secret Pass for VS POC").toString();
    console.log("encryptedText: " + encryptedText);
    const decryptedText = this.CryptoJS.AES.decrypt(encryptedText, "Secret Pass for VS POC").toString(this.CryptoJS.enc.Utf8)
    console.log("decryptedText: " + decryptedText);
  },
  destroyed() {
  },
  computed: {

  },
  methods: {
    onNewScan() {
      this.result = null;
      window.scrollTo(0, 0);
    },
    startFrontCamera () {
      this.camera = 'front'
    },

    onCameraChange (promise) {
      promise.catch(error => {
        const cameraMissingError = error.name === 'OverconstrainedError'
        const triedFrontCamera = this.camera === 'front'

        if (triedFrontCamera && cameraMissingError) {
          // no front camera on this device
        }
      })
    },
    onDecode (result) {
      this.result = this.CryptoJS.AES.decrypt(result, "Secret Pass for VS POC").toString(this.CryptoJS.enc.Utf8);

      console.log("result", result);
      console.log("decrypted result", this.result);
    },

    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "ERROR: you need to grant camera access permission"
        } else if (error.name === 'NotFoundError') {
          this.error = "ERROR: no camera on this device"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.error = "ERROR: is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: Stream API is not supported in this browser"
        } else if (error.name === 'InsecureContextError') {
          this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    }
  },
};
</script>
